import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';
import { Grid, Row, Col } from 'react-bootstrap';
import { Card } from 'components/Card/Card.jsx';
import { StatsCard } from 'components/StatsCard/StatsCard.jsx';
import firebase from 'config/fire.jsx';
import { CSVLink } from 'react-csv';
import Button from 'components/CustomButton/CustomButton.jsx';
import logo from 'assets/img/favicon.png';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSpinner: '',
      tableAllData: [],
      tableMonthData: [],
      tableWeekData: [],
      currentDate:
        new Date().getDate() +
        '/' +
        (new Date().getMonth() + 1) +
        '/' +
        new Date().getFullYear() +
        ' a las ' +
        new Date().getHours() +
        ':' +
        (new Date().getMinutes() < 10 ? '0' : '') +
        new Date().getMinutes(),
      visitsDay: '',
      visitsWeek: '',
      visitsMonth: '',
      catalogues: '',
      graphBarData: {
        labels: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'],
        series: [[0, 0, 0, 0, 0]]
      },
      graphBarOptions: {
        high: 50,
        low: 0,
        axisX: {
          showGrid: false
        },
        height: '245px'
      },
      graphLineData: {
        labels: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'],
        series: [[0, 0, 0, 0, 0]]
      },
      graphLineOptions: {
        high: 50,
        low: 0,
        showArea: false,
        height: '245px',
        axisX: {
          showGrid: true
        },
        lineSmooth: true,
        showLine: true,
        showPoint: true,
        fullWidth: true,
        chartPadding: {
          right: 50
        }
      }
    };

    let date = new Date();
    let today = new Date(
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    );
    let todayAux = new Date(
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    );
    let d = new Date(date.getFullYear() + '-' + (date.getMonth() + 1) + '-01');
    let thisWeek = new Date(
      todayAux.setDate(date.getDate() - (date.getDay() - 1))
    );
    let firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firebase
      .firestore()
      .collection('visits')
      .where('createdAt', '>=', today)
      .get()
      .then(snapshot => {
        this.setState({ visitsDay: snapshot.size });
      })
      .catch(err => {
        console.error('Error getting documents', err);
      });

    firebase
      .firestore()
      .collection('visits')
      .where('createdAt', '>=', d)
      .where('catalogue', '==', true)
      .get()
      .then(snapshot => {
        this.setState({ catalogues: snapshot.size });
      })
      .catch(err => {
        console.error('Error getting documents', err);
      });

    // visitas realizadas este mes
    firebase
      .firestore()
      .collection('visits')
      .where('createdAt', '>=', d)
      .get()
      .then(snapshot => {
        this.setState({ visitsMonth: snapshot.size });
      })
      .catch(err => {
        console.error('Error getting documents', err);
      });

    // Visitas realizadas esta semana
    firebase
      .firestore()
      .collection('visits')
      .where('createdAt', '>=', thisWeek)
      .get()
      .then(snapshot => {
        let users = [];
        let value = [0, 0, 0, 0, 0];
        let expos = [0, 0, 0, 0, 0];
        let catal = [0, 0, 0, 0, 0];
        let auxGraphLineData = this.state.graphLineData;
        let auxGraphBarData = this.state.graphBarData;
        this.setState({ visitsWeek: snapshot.size });
        for (let doc of snapshot.docs) {
          users.push({ name: doc.data().name, email: doc.data().userMail });
          switch (new Date(doc.data().createdAt.toDate()).getDay()) {
            case 1:
              value[0]++;
              if (doc.data().catalogue) catal[0]++;
              if (doc.data().expositor) expos[0] += doc.data().expositor.length;
              break;
            case 2:
              value[1]++;
              if (doc.data().catalogue) catal[1]++;
              if (doc.data().expositor) expos[1] += doc.data().expositor.length;
              break;
            case 3:
              value[2]++;
              if (doc.data().catalogue) catal[2]++;
              if (doc.data().expositor) expos[2] += doc.data().expositor.length;
              break;
            case 4:
              value[3]++;
              if (doc.data().catalogue) catal[3]++;
              if (doc.data().expositor) expos[3] += doc.data().expositor.length;
              break;
            case 5:
              value[4]++;
              if (doc.data().catalogue) catal[4]++;
              if (doc.data().expositor) expos[4] += doc.data().expositor.length;
              break;
            default:
              break;
          }
        }
        var counts = {};
        users.forEach(function(x) {
          counts[x.email] = (counts[x.email] || 0) + 1;
        });
        let auxTableWeekData = [];
        let promises = [];

        for (let obj in counts) {
          promises.push(
            firebase
              .firestore()
              .collection('users')
              .doc(obj)
              .get()
              .then(snapshot => {
                if (snapshot.data()) {
                  auxTableWeekData.push({
                    name: `${snapshot.data().name} ${snapshot.data().surname}`,
                    visit: counts[obj]
                  });
                }
              })
          );
        }
        Promise.all(promises).then(() => {
          let sortList = auxTableWeekData.sort(this.compare);
          this.setState({ tableWeekData: sortList });
        });

        auxGraphLineData.series = [value];
        auxGraphBarData.series = [catal, expos];
        this.setState({
          graphLineData: auxGraphLineData,
          graphBarData: auxGraphBarData
        });
      })
      .catch(err => {
        console.error('Error getting documents', err);
      });

    this.getVisitTableData('tableMonthData', firstMonthDay);
    this.getVisitTableData('tableAllData');
  }

  getVisitTableData = (stateProperty, limitDay) => {
    let ref = firebase.firestore().collection('visits');

    if (limitDay) {
      ref = ref.where('createdAt', '>=', limitDay);
    }

    ref.get().then(snapshot => {
      let users = [];
      for (let doc of snapshot.docs) {
        if (doc.data().userMail) {
          users.push({ name: doc.data().name, email: doc.data().userMail });
        }
      }
      var counts = {};
      users.forEach(function(x) {
        counts[x.email] = (counts[x.email] || 0) + 1;
      });
      let auxTableMonthData = [];
      let promises = [];
      for (let obj in counts) {
        promises.push(
          firebase
            .firestore()
            .collection('users')
            .doc(obj)
            .get()
            .then(snapshot => {
              if (snapshot.data()) {
                auxTableMonthData.push({
                  name: `${snapshot.data().name} ${snapshot.data().surname}`,
                  visit: counts[obj]
                });
              }
            })
        );
      }
      Promise.all(promises).then(() => {
        let sortList = auxTableMonthData.sort(this.compare);
        this.setState({ [stateProperty]: sortList, loadingSpinner: 'none' });
      });
    });
  };
  compare = (a, b) => {
    if (a.visit > b.visit) return -1;
    if (a.visit < b.visit) return 1;
    return 0;
  };

  componentDidMount() {}
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json['names'].length; i++) {
      var type = 'fa fa-circle text-' + json['types'][i];
      legend.push(<i className={type} key={i} />);
      legend.push(' ');
      legend.push(json['names'][i]);
    }
    return legend;
  }
  prepareCsvData = stateProperty => {
    let csvObj = this.state[stateProperty].map((row, index) => {
      return {
        Posicion: index + 1,
        Nombre: row.name,
        Visitas: row.visit
      };
    });
    return csvObj;
  };
  render() {
    return (
      <React.Fragment>
        <div
          className="divSpinner"
          id="dashboard"
          style={{ display: this.state.loadingSpinner }}
        >
          <div className="spinner">
            <img
              src={logo}
              alt="logo_image"
              style={{ height: '90px', opacity: '0.7'}}
            />
          </div>
        </div>
        <div className="content background-image">
          <Grid fluid>
            <Row>
              <Col lg={3} sm={6}>
                <StatsCard
                  bigIcon={
                    <i
                      style={{ fontWeight: '100' }}
                      className="fa fa-vcard-o text-muted"
                    />
                  }
                  statsText="Visitas hoy"
                  statsValue={this.state.visitsDay}
                  statsIcon={<i className="fa fa-refresh" />}
                  statsIconText="Hoy"
                />
              </Col>
              <Col lg={3} sm={6}>
                <StatsCard
                  bigIcon={
                    <i
                      style={{ fontWeight: '100' }}
                      className="fa fa-briefcase text-muted"
                    />
                  }
                  statsText="Visitas"
                  statsValue={this.state.visitsWeek}
                  statsIcon={<i className="fa fa-refresh" />}
                  statsIconText="Semana actual"
                />
              </Col>
              <Col lg={3} sm={6}>
                <StatsCard
                  bigIcon={
                    <i
                      style={{ fontWeight: '100' }}
                      className="fa fa-calendar text-muted"
                    />
                  }
                  statsText="Visitas mes"
                  statsValue={this.state.visitsMonth}
                  statsIcon={
                    <i
                      style={{ fontWeight: '100' }}
                      className="fa fa-calendar-o"
                    />
                  }
                  statsIconText="Mes actual"
                />
              </Col>
              <Col lg={3} sm={6}>
                <StatsCard
                  bigIcon={
                    <i
                      style={{ fontWeight: '100' }}
                      className="fa fa-book text-muted"
                    />
                  }
                  statsText="Catálogos"
                  statsValue={this.state.catalogues}
                  statsIcon={<i className="fa fa-calendar-o" />}
                  statsIconText="Mes actual"
                />
              </Col>
            </Row>
            <Row>
              <Col xl={8} lg={6} md={6} sm={12}>
                <Card
                  statsIcon="fa fa-history"
                  id="chartHours"
                  title="Visitas semana actual"
                  stats={`Información actualizada el ${this.state.currentDate}`}
                  content={
                    <div className="ct-chart">
                      <ChartistGraph
                        data={this.state.graphLineData}
                        type="Line"
                        options={this.state.graphLineOptions}
                      />
                    </div>
                  }
                  legend={
                    <div className="legend">
                      {this.createLegend({
                        names: ['Visitas'],
                        types: ['info']
                      })}
                    </div>
                  }
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={8}>
                <Card
                  id="chartActivity"
                  title="Catálogos y expositores semana acual"
                  stats={`Información actualizada el ${this.state.currentDate}`}
                  statsIcon="fa fa-history"
                  content={
                    <div className="ct-chart">
                      <ChartistGraph
                        data={this.state.graphBarData}
                        type="Bar"
                        options={this.state.graphBarOptions}
                      />
                    </div>
                  }
                  legend={
                    <div className="legend">
                      {this.createLegend({
                        names: ['Catálogos', 'Expositores'],
                        types: ['info', 'danger']
                      })}
                    </div>
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col sm={6} lg={4}>
                <Card
                  title="Ranking visitas semana"
                  stats={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%'
                      }}
                    >
                      <CSVLink
                        separator={';'}
                        data={this.prepareCsvData('tableWeekData')}
                        filename={'Ranking semana.csv'}
                      >
                        <Button simple>
                          <i className="fa fa-file-excel-o" />
                          Exportar Excel
                        </Button>
                      </CSVLink>
                    </div>
                  }
                  content={
                    <div
                      style={{
                        height: '290px',
                        overflowX: 'hidden'
                      }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col" />
                            <th scope="col">Nombre</th>
                            <th scope="col">Visitas</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tableWeekData.map((e, index) => {
                            return (
                              <tr
                                key={index}
                                style={
                                  index % 2 === 0
                                    ? { background: '#00000008' }
                                    : { background: '#fff' }
                                }
                              >
                                <th scope="row">{index + 1}</th>
                                <td>{e.name}</td>
                                <td>{e.visit}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                />
              </Col>
              <Col sm={6} lg={4}>
                <Card
                  title="Ranking visitas este mes"
                  stats={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%'
                      }}
                    >
                      <CSVLink
                        separator={';'}
                        data={this.prepareCsvData('tableMonthData')}
                        filename={'Ranking mensual.csv'}
                      >
                        <Button simple>
                          <i className="fa fa-file-excel-o" />
                          Exportar Excel
                        </Button>
                      </CSVLink>
                    </div>
                  }
                  content={
                    <div
                      style={{
                        height: '290px',
                        overflowX: 'hidden'
                      }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col" />
                            <th scope="col">Nombre</th>
                            <th scope="col">Visitas</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tableMonthData.map((e, index) => {
                            return (
                              <tr
                                key={index}
                                style={
                                  index % 2 === 0
                                    ? { background: '#00000008' }
                                    : { background: '#fff' }
                                }
                              >
                                <th scope="row">{index + 1}</th>
                                <td>{e.name}</td>
                                <td>{e.visit}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                />
              </Col>
              <Col sm={6} lg={4}>
                <Card
                  title="Ranking visitas totales"
                  stats={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%'
                      }}
                    >
                      <CSVLink
                        separator={';'}
                        data={this.prepareCsvData('tableAllData')}
                        filename={'Ranking global.csv'}
                      >
                        <Button simple>
                          <i className="fa fa-file-excel-o" />
                          Exportar Excel
                        </Button>
                      </CSVLink>
                    </div>
                  }
                  content={
                    <div
                      style={{
                        height: '290px',
                        overflowX: 'hidden'
                      }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col" />
                            <th scope="col">Nombre</th>
                            <th scope="col">Visitas</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tableAllData.map((e, index) => {
                            return (
                              <tr
                                key={index}
                                style={
                                  index % 2 === 0
                                    ? { background: '#00000008' }
                                    : { background: '#fff' }
                                }
                              >
                                <th scope="row">{index + 1}</th>
                                <td>{e.name}</td>
                                <td>{e.visit}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
