import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import Sidebar from 'components/Sidebar/Sidebar'
import dashboardRoutes from 'routes/dashboard.jsx'
import firebase from 'config/fire.jsx'
import logo from 'assets/img/favicon.png'

class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.componentDidMount = this.componentDidMount.bind(this)

    this.state = {
      currentUser: null,
      loading: true
    }
  }

  componentDidMount () {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(doc => {
            this.setState({ loading: false })
            if (doc.exists) {
              var routes = dashboardRoutes
              this.setState({
                currentUser: doc.data(),
                userInfo: user,
                dashboardRoutes: routes
              })
            } else {
            }
          })
          .catch(function (error) {})
      } else {
        this.props.history.push('/public/login')
        this.setState({
          currentUser: null,
          loading: false
        })
      }
    })
  }

  componentDidUpdate (e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }
    // if (e.history.action === 'PUSH') {
    //   document.documentElement.scrollTop = 0;
    //   document.scrollingElement.scrollTop = 0;
    //   this.refs.mainPanel.scrollTop = 0;
    // }
  }

  render () {
    if (this.state.loading) {
      return (
        <div className='content background-image' id='inicio'>
          <div className='divSpinner' style={{ alignItems: 'center' }}>
            <div className='spinner' id='login'>
              <img src={logo} alt='logo_image' style={{ height: '90px' }} />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='wrapper'>
          <Sidebar {...this.props} />
          <div id='main-panel' className='main-panel' ref='mainPanel'>
            <Header {...this.props} />
            <Switch>
              {dashboardRoutes.map((prop, key) => {
                if (prop.redirect)
                  {return <Redirect from={prop.path} to={prop.to} key={key} />;}
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                )
              })}
            </Switch>
            <Footer />
          </div>
        </div>
      )
    }
  }
}

export default Dashboard
