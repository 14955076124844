import React, { Component } from "react";
import { Grid } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Grid fluid>
          <p className="copyright pull-right">
            &copy; {new Date().getFullYear()}{" "} Desarrollado por
            <a href="http://www.estudiocactus.com" target="_blank" rel="noopener noreferrer" style={{color: '#96BF4F'}}> Estudio Cactus</a>
          </p>
        </Grid>
      </footer>
    );
  }
}

export default Footer;
