import React from 'react';
import TableNotifications from 'components/TableInfo/TableNotifications.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';
import { CSVLink } from 'react-csv';
import logo from 'assets/img/favicon.png';

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSpinner: '',
      valueTable: []
    };
  }
  setLoading = () => {
    this.setState({ loadingSpinner: 'none' });
  };

  prepareCsvVisits = () => {
    let csvObj = this.state.valueTable.map((row, index) => {
      return {
        Comercial: row.name,
        Fecha: row.date,
        Alerta: row.alert
      };
    });
    return csvObj;
  };
  render() {
    return (
      <React.Fragment>
         <div
          className="divSpinner"
          id="dashboard"
          style={{ display: this.state.loadingSpinner }}
        >
          <div className="spinner">
            <img
              src={logo}
              alt="logo_image"
              style={{ height: '90px', opacity: '0.7'}}
            />
          </div>
        </div>
        <div className="content background-image">
          <div style={{ paddingLeft: '15px', paddingBottom: '30px' }}>
            <CSVLink
              separator={';'}
              data={this.prepareCsvVisits()}
              filename={'Alertas.csv'}
            >
              <Button bsStyle="default" fill style={{ marginRight: '30px' }}>
                Exportar Excel
              </Button>
            </CSVLink>
          </div>
          <TableNotifications
            title="Notificaciones"
            subTitle="Listado de las notificaciones enviadas a los usuarios"
            setLoading={() => this.setLoading()}
            value={value => this.setState({ valueTable: value })}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Notifications;
