import React from 'react';
// View components
import { Grid, Row, Col } from 'react-bootstrap';
// React-Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';
// Firebase
import fire from 'config/fire.jsx';

class TableNotifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredVisits: [],
      loading: true
    };
  }
  componentDidMount() {
    let actionsData = [];
    fire
      .firestore()
      .collection('alerts')
      .orderBy('date', 'desc')
      .get()
      .then(filteredVisits => {
        for (let doc of filteredVisits.docs) {
          let auxDate;
          if (doc.data().date !== '') {
            auxDate = doc.data().date.toDate();
            auxDate = new Intl.DateTimeFormat('es-ES', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }).format(auxDate);
          } else {
            auxDate = '';
          }
          actionsData.push({
            ...doc.data(),
            date: auxDate
          });
        }
        this.setState(
          {
            actionsData: actionsData,
            loading: false
          },
          () => {
            this.props.setLoading();
            this.props.value(this.state.actionsData);
          }
        );
      });
  }

  render() {
    const columns = [
      {
        Header: 'Comercial',
        accessor: 'name',
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            {`${row.name} ${row._original.surname}`}
          </div>
        )
      },
      {
        Header: 'Fecha',
        accessor: 'date',
        Cell: ({ row }) => (
          <div
            style={{
              fontFamily: 'Monospace',
              textAlign: 'center'
            }}
          >
            {row.date}
          </div>
        )
      },
      {
        Header: 'Alerta',
        accessor: 'alert',
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            {row.alert}
          </div>
        )
      }
    ];

    return (
      <div>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <ReactTable
                style={{
                  borderRadius: '25px',
                  padding: '10px',
                  background: 'linear-gradient(#F4F5F8, #F1F3F6)',
                  whiteSpace: 'nowrap'
                }}
                filterable
                loading={this.state.loading}
                defaultPageSize={10}
                previousText="Anterior"
                nextText="Siguiente"
                loadingText=""
                pageText="Página"
                rowsText="filas"
                ofText="de"
                noDataText="No se han encontrado proyectos"
                defaultFilterMethod={(filter, row) => {
                  if (row[filter.id] !== undefined) {
                    if (typeof row[filter.id] === 'string') {
                      return row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                    } else {
                      return row[filter.id].key
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                    }
                  } else {
                    return false;
                  }
                }}
                data={this.state.actionsData}
                columns={columns}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default TableNotifications;
