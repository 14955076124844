import Dashboard from 'views/Dashboard/Dashboard'
import Notifications from 'views/Notifications/Notifications'
import Architects from 'views/Architects/Architects'
import Reformist from 'views/Reformist/Reformist'
import Decorators from 'views/Decorators/Decorators'
import Kitchens from 'views/Kitchens/Kitchens'
import MarbleWorkers from 'views/MarbleWorkers/MarbleWorkers'
import Users from 'views/Users/Users'
import UserVisits from '../views/UserVisits/UserVisits'

const adminRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'pe-7s-graph',
    component: Dashboard,
    visible: true
  },
  {
    path: '/architects',
    name: 'Arquitectos',
    icon: 'pe-7s-config',
    component: Architects,
    visible: true
  },
  {
    path: '/reformist',
    name: 'Reformista',
    icon: 'pe-7s-tools',
    component: Reformist,
    visible: true
  },
  {
    path: '/decorators',
    name: 'Decoradores',
    icon: 'pe-7s-photo',
    component: Decorators,
    visible: true
  },
  {
    path: '/marble-workers',
    name: 'Marmolistas',
    icon: 'pe-7s-diamond',
    component: MarbleWorkers,
    visible: true
  },
  {
    path: '/kitchens',
    name: 'Tiendas de cocina',
    icon: 'pe-7s-cart',
    component: Kitchens,
    visible: true
  },
  {
    path: '/users',
    name: 'Usuarios',
    icon: 'pe-7s-users',
    component: Users,
    visible: true
  },
  {
    path: '/notifications',
    name: 'Notificaciones',
    icon: 'pe-7s-bell',
    component: Notifications,
    visible: true
  },
  {
    path: '/user-visits',
    name: 'Visitas del usuario',
    component: UserVisits,
    visible: false
  },

  { redirect: true, path: '/', to: '/dashboard', name: 'Dashboard' }
]

export default adminRoutes
