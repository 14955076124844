import React from 'react'

// View components
import { Grid, Row, Col } from 'react-bootstrap'
import GoogleMap from '../GoogleMap/GoogleMap';

class InfoModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return (
      <div>
        <Grid fluid>
          <Row>
            <Col md={12}>
            <h2 style={{textAlign: 'center'}}>{this.props.data.title}</h2>
            <div>
            {this.props.data.type === 'map' ? (
                <GoogleMap
                  lat={this.props.data.data1}
                  lng={this.props.data.data2}
                />
              ) : (
                <p>{this.props.data.data1}</p>
            )}
            </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default (InfoModal)
