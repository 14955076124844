import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export class MapContainer extends Component {
  render() {
    return (
      <div
        id="map"
        style={{
          position: 'relative',
          height: 'calc(100% - 100px)',
          minWidth: '330px'
        }}
      >
        <Map
          google={this.props.google}
          zoom={16}
          style={{}}
          initialCenter={{
            lat: this.props.lat,
            lng: this.props.lng
          }}
        >
          <Marker />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyChNlAXlmbttsaQuqlOu-xxEV7VV6zPxF4'
})(MapContainer);
