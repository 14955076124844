import React from 'react';
// View components
import { Grid, Row, Col, Modal } from 'react-bootstrap';
// React-Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';
// Button
import Button from 'components/CustomButton/CustomButton.jsx';
// Firebase
import fire from 'config/fire.jsx';
import axios from 'axios';

import { Link } from 'react-router-dom';
import UserVisits from '../../views/UserVisits/UserVisits';

class TableUsers extends React.Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      filteredVisits: [],
      loading: true,
      uid: '',
      mail: ''
    };

  }

  componentDidMount() {
    fire
      .firestore()
      .collection('users')
      .onSnapshot(filteredVisits => {
        let actionsData = [];
        for (let doc of filteredVisits.docs) {
          actionsData.push({
            ...doc.data()
          });
        }
        this.setState(
          {
            actionsData: actionsData,
            loading: false
          },
          () => {
            this.props.setLoading('none');
          }
        );
      });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(uid, mail) {
    this.setState({
      show: true,
      uid: uid,
      mail: mail
    });
  }

  showUser(uid, mail) {
    console.log('showUser --> ', uid)
    console.log('showUser --> ', mail)
  }

  deleteUser(uid, mail) {
    this.handleClose()
    this.props.setLoading('');
    fire
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        axios({
          method: 'GET',
          url:
            'https://us-central1-marmoles-sol-comerciales.cloudfunctions.net/api/deleteUser',
          headers: {
            Authorization: 'Bearer ' + idToken
          },
          params: {
            uid: uid,
            email: mail
          }
        }).then(
          result => {
            this.handleClose();
            this.props.setLoading('none');
          },
          error => {
            alert('Ha ocurrido un error');
            this.handleClose();
            this.props.setLoading('none');
            console.error(error);
          }
        );
      })
      .catch(function(error) {
        alert('Ha ocurrido un error');
        this.handleClose();
        this.props.setLoading('');
      });
  }

  render() {
    const columns = [
      {
        Header: 'Nombre',
        accessor: 'name',
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <Link
              to={{
                pathname: '/user-visits',
                state: { uid: row._original.uid, mail: row._original.mail}
              }}
            >
            {row.name}
            </Link>
          </div>
        )
        // Cell: ({ row }) => (
        //   <div
        //     style={{
        //       textAlign: 'center'
        //     }}
        //   >
        //     {row.name}
        //   </div>
        // )
      },
      {
        Header: 'Apellidos',
        accessor: 'surname',
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            {row.surname}
          </div>
        )
      },
      {
        Header: 'Mail',
        accessor: 'mail',
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            {row.mail}
          </div>
        )
      },
      {
        Header: 'Borrar',
        accessor: 'uid',
        filterable: false,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <Button
              onClick={() => {
                this.handleShow(row._original.uid, row._original.mail);
              }}
              bsStyle="danger"
              simple
            >
              <i className="fa fa-times" />
            </Button>{' '}
          </div>
        )
      }
    ];

    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleClose} bsSize="small">
          <Modal.Body>
            <h4 style={{ textAlign: 'center' }}>
              ¿Seguro que quieres eliminar el usuario?
            </h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.deleteUser(this.state.uid, this.state.mail)}
            >
              Eliminar
            </Button>
            <Button onClick={this.handleClose}>Cancelar</Button>
          </Modal.Footer>
        </Modal>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <ReactTable
                style={{
                  borderRadius: '25px',
                  padding: '10px',
                  background: 'linear-gradient(#F4F5F8, #F1F3F6)',
                  whiteSpace: 'nowrap'
                }}
                filterable
                loading={this.state.loading}
                defaultPageSize={10}
                previousText="Anterior"
                nextText="Siguiente"
                loadingText=""
                pageText="Página"
                rowsText="filas"
                ofText="de"
                noDataText="No se han encontrado proyectos"
                defaultFilterMethod={(filter, row) => {
                  if (row[filter.id] !== undefined) {
                    if (typeof row[filter.id] === 'string') {
                      return row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                    } else {
                      return row[filter.id].key
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                    }
                  } else {
                    return false;
                  }
                }}
                data={this.state.actionsData}
                columns={columns}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default TableUsers;
