import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import pagesRoutes from 'routes/pages.jsx';

import LoginImg from 'assets/img/background-image.jpg';

class Pages extends React.Component {
  componentDidMount() {
    document.body.style.overflow = 'unset';
  }
  render() {
    return (
      <div>
        <div>
          <div
            style={{
              backgroundImage: 'url(' + LoginImg + ')',
              backgroundSize: 'cover',
              height: '100%'
            }}
          >
            <Switch>
              {pagesRoutes.map((prop, key) => {
                if (prop.collapse) {
                  return null;
                }
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                }
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Pages;
