// React
import React from 'react'
// Components
import TableInfo from 'components/TableInfo/TableInfo.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
// React csv
import { CSVLink } from 'react-csv'
// Assets
import logo from 'assets/img/favicon.png'
// dayjs
import dayjs from 'dayjs'

/* eslint-disable no-prototype-builtins */
class Kitchens extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loadingSpinner: '',
      valueTable: []
    }
  }

  setLoading = () => {
    this.setState({ loadingSpinner: 'none' })
  };

  prepareCsvContacts = () => {
    const csvObj = this.state.valueTable.map((row, index) => {
      return {
        Comercial: row.userMail,
        Cliente: row.client,
        Dirección: row.direction,
        'Dirección 2': row.direction2,
        Población: row.town,
        Provincia: row.city,
        'Código Postal': row.cp,
        Telefono: row.phone,
        Mail: row.mail,
        'Mail 2': row.mail2,
        Contacto: row.contact,
        Marmolista: row.marbleKitchen,
        'Fecha de registro': row.createdAt
      }
    })
    const resArr = []
    csvObj.forEach(function (item) {
      const i = resArr.findIndex((x) => x.Mail === item.Mail)
      if (i <= -1) {
        resArr.push(item)
        return
      }

      // This essentially makes the contact's register date and commercial on the exported .csv be the ones of the first registry/visit.
      // They wanted contacts to be tied to a specific commercial when exporting, but there's no relation on the database, so this is the workaround
      if (dayjs(item['Fecha de registro']).isBefore(dayjs(resArr[i]['Fecha de registro']))) {
        resArr[i] = {
          ...resArr[i],
          Comercial: item.Comercial,
          'Fecha de registro': item['Fecha de registro']
        }
      }
    })
    return resArr
  };

  prepareCsvVisits = () => {
    const csvObj = this.state.valueTable.map((row, index) => {
      return {
        Comercial: row.userMail,
        Cliente: row.client,
        Dirección: row.direction,
        'Dirección 2': row.direction2,
        Población: row.town,
        Provincia: row.city,
        'Código Postal': row.cp,
        Telefono: row.phone,
        Mail: row.mail,
        'Mail 2': row.mail2,
        Contacto: row.contact,
        Marmolista: row.marbleKitchen,
        Fecha: row.createdAt,
        Catálogo: row.catalogue ? 'Entregado' : 'No entregado',
        Granito: row.granite ? 'Entregado' : 'No entregado',
        'Enc exp': row.worktop ? 'Entregado' : 'No entregado',
        'Enc exp Cover': row.worktopCover ? 'Entregado' : 'No entregado',
        'Exp Cover': row.hasOwnProperty('expoCover')
          ? row.expoCover
            ? 'Entregado'
            : 'No entregado'
          : 'No entregado',
        'Acceso Panel': row.panelAccess ? 'Sí' : 'No',
        Muestras: row.piece ? 'Entregado' : 'No entregado',
        'Expositores entregados': row.expositor,
        Notas: row.notes
      }
    })
    return csvObj
  };

  render () {
    return (
      <>
        <div
          className='divSpinner'
          id='dashboard'
          style={{ display: this.state.loadingSpinner }}
        >
          <div className='spinner'>
            <img
              src={logo}
              alt='logo_image'
              style={{ height: '90px', opacity: '0.7' }}
            />
          </div>
        </div>

        <div className='content background-image'>
          <div style={{ paddingLeft: '15px', paddingBottom: '30px' }}>
            <CSVLink
              separator=';'
              data={this.prepareCsvVisits()}
              filename='Visitas Tiendas cocina.csv'
            >
              <Button bsStyle='default' fill style={{ marginRight: '30px' }}>
                Exportar Excel
              </Button>
            </CSVLink>

            <CSVLink
              separator=';'
              data={this.prepareCsvContacts()}
              filename='Contactos Tiendas cocina.csv'
            >
              <Button bsStyle='default' fill>
                Exportar Contactos
              </Button>
            </CSVLink>
          </div>

          <TableInfo
            title='Tiendas de cocina'
            subTitle='Visitas realizadas a tiendas de cocina'
            type='kitchen'
            setLoading={() => this.setLoading()}
            value={value => this.setState({ valueTable: value })}
          />
        </div>
      </>
    )
  }
}

export default Kitchens
