import React from 'react'
import TableInfo from 'components/TableInfo/TableInfo.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import { CSVLink } from 'react-csv'
import logo from 'assets/img/favicon.png'

class UserVisits extends React.Component {
  constructor (props) {
    super(props)

    console.log('props --> ', props.location.state)

    this.state = {
      loadingSpinner: '',
      valueTable: []
    }
  }

  setLoading = () => {
    this.setState({ loadingSpinner: 'none' })
  };

  prepareCsvContacts = () => {
    const csvObj = this.state.valueTable.map((row, index) => {
      return {
        Comercial: row.userMail,
        Cliente: row.client,
        Dirección: row.direction,
        'Dirección 2': row.direction2,
        Población: row.town,
        Provincia: row.city,
        'Código Postal': row.cp,
        Telefono: row.phone,
        Mail: row.mail,
        'Mail 2': row.mail2,
        Contacto: row.contact
      }
    })
    const resArr = []
    csvObj.filter(function (item) {
      var i = resArr.findIndex(x => x.Mail === item.Mail)
      if (i <= -1) {
        resArr.push(item)
      }
      return null
    })
    return resArr
  };

  prepareCsvVisits = () => {
    const csvObj = this.state.valueTable.map((row, index) => {
      return {
        Comercial: row.userMail,
        Cliente: row.client,
        Dirección: row.direction,
        'Dirección 2': row.direction2,
        Población: row.town,
        Provincia: row.city,
        'Código Postal': row.cp,
        Telefono: row.phone,
        Mail: row.mail,
        'Mail 2': row.mail2,
        Contacto: row.contact,
        Fecha: row.createdAt,
        Catálogo: row.catalogue ? 'Entregado' : 'No entregado',
        Granito: row.granite ? 'Entregado' : 'No entregado',
        'Enc exp': row.worktop ? 'Entregado' : 'No entregado',
        'Enc exp Cover': row.worktopCover ? 'Entregado' : 'No entregado',
        'Exp Cover': Object.prototype.hasOwnProperty.call(row, 'expoCover')
          ? row.expoCover
            ? 'Entregado'
            : 'No entregado'
          : 'No entregado',
        'Acceso Panel': row.panelAccess ? 'Sí' : 'No',
        Muestras: row.piece ? 'Entregado' : 'No entregado',
        'Expositores entregados': row.expositor,
        Notas: row.notes
      }
    })
    return csvObj
  };

  render () {
    return (
      <>
        <div
          className='divSpinner'
          id='dashboard'
          style={{ display: this.state.loadingSpinner }}
        >
          <div className='spinner'>
            <img
              src={logo}
              alt='logo_image'
              style={{ height: '90px', opacity: '0.7' }}
            />
          </div>
        </div>

        <div className='content background-image'>
          <div style={{ paddingLeft: '15px', paddingBottom: '30px' }}>
            <CSVLink
              separator=';'
              data={this.prepareCsvVisits()}
              filename='Visitas Usuario.csv'
            >
              <Button bsStyle='default' fill style={{ marginRight: '30px' }}>
                Exportar Excel
              </Button>
            </CSVLink>
            <CSVLink
              separator=';'
              data={this.prepareCsvContacts()}
              filename='Contactos Visitas.csv'
            >
              <Button bsStyle='default' fill>
                Exportar Contactos
              </Button>
            </CSVLink>
          </div>
          <TableInfo
            title='Visitas del usuario'
            subTitle='Visitas realizadas de un usuario'
            email={this.props.location.state.mail}
            setLoading={() => this.setLoading()}
            value={value => this.setState({ valueTable: value })}
          />
        </div>
      </>
    )
  }
}

export default UserVisits
