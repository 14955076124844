import fb from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
  apiKey: "AIzaSyBkV4PH2vWvS-xp2I9E1w2rUcHZI8AKJgY",
  authDomain: "marmoles-sol-comerciales.firebaseapp.com",
  databaseURL: "https://marmoles-sol-comerciales.firebaseio.com",
  projectId: "marmoles-sol-comerciales",
  storageBucket: "marmoles-sol-comerciales.appspot.com",
  messagingSenderId: "280479563871"
}

const settings = { timestampsInSnapshots: true }
const firebase = fb.initializeApp(config)
firebase.firestore().settings(settings)

export default firebase
