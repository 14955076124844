import React from 'react';
import TableUsers from 'components/TableInfo/TableUsers.jsx';
import { Grid, Row, Col, Modal } from 'react-bootstrap';
// Firebase
import fire from 'config/fire.jsx';
import axios from 'axios';
import logo from 'assets/img/favicon.png';
import { Card } from 'components/Card/Card.jsx';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      name: '',
      surname: '',
      mail: '',
      password: '',
      id: '',
      push_uid: '',
      loadingSpinner: '',
      show: false,
      modalTitle: ''
    };
  }
  setLoading = value => {
    this.setState({ loadingSpinner: value });
  };
  handleClose() {
    this.setState({ show: false });
  }

  handleShow(title) {
    this.setState({ show: true, modalTitle: title });
  }

  changeValue(event, stateName) {
    this.setState({ [stateName]: event.target.value }, () => {});
  }

  createUser = e => {
    e.preventDefault();
    this.setState({ loadingSpinner: '' });
    fire
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        axios({
          method: 'GET',
          url:
            'https://us-central1-marmoles-sol-comerciales.cloudfunctions.net/api/createUser',
          headers: {
            Authorization: 'Bearer ' + idToken
          },
          params: {
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.mail,
            password: this.state.password
          }
        }).then(
          result => {
            this.handleShow('Usuario creado correctamente');
            this.setState({ loadingSpinner: 'none' });
            this.setState({ name: '', surname: '', mail: '', password: '' });
          },
          error => {
            this.handleShow('Ha ocurrido un error, intentelo de nuevo');
            this.setState({ loadingSpinner: 'none' });
            console.error(error);
          }
        );
      })
      .catch(function(error) {
        this.handleShow('Ha ocurrido un error, intentelo de nuevo');
        this.setState({ loadingSpinner: 'none' });
      });
  };

  render() {
    return (
      <React.Fragment>
         <div
          className="divSpinner"
          id="dashboard"
          style={{ display: this.state.loadingSpinner }}
        >
          <div className="spinner">
            <img
              src={logo}
              alt="logo_image"
              style={{ height: '90px', opacity: '0.7' }}
            />
          </div>
        </div>
        <div className="content background-image">
          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            bsSize="small"
          >
            <Modal.Body>
              <h4 style={{ textAlign: 'center' }}>{this.state.modalTitle}</h4>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Aceptar</Button>
            </Modal.Footer>
          </Modal>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Crear Usuario"
                  content={
                    <form id="userForm" onSubmit={this.createUser}>
                      <FormInputs
                        required
                        ncols={['col-md-6', 'col-md-6']}
                        proprieties={[
                          {
                            value: this.state.name,
                            required: true,
                            label: 'Nombre',
                            type: 'text',
                            bsClass: 'form-control',
                            placeholder: 'Nombre',
                            onChange: event => this.changeValue(event, 'name')
                          },
                          {
                            value: this.state.surname,
                            required: true,
                            label: 'Apellidos',
                            type: 'text',
                            bsClass: 'form-control',
                            placeholder: 'Apellidos',
                            onChange: event =>
                              this.changeValue(event, 'surname')
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={['col-md-6', 'col-md-6']}
                        proprieties={[
                          {
                            pattern:
                              '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
                            value: this.state.mail,
                            required: true,
                            label: 'Email',
                            type: 'email',
                            bsClass: 'form-control',
                            placeholder: 'Email',
                            onChange: event => this.changeValue(event, 'mail')
                          },
                          {
                            value: this.state.password,
                            required: true,
                            label: 'Contraseña',
                            type: 'password',
                            bsClass: 'form-control',
                            placeholder: 'Contraseña',
                            onChange: event =>
                              this.changeValue(event, 'password')
                          }
                        ]}
                      />
                      <Button type="submit" bsStyle="default" pullRight fill>
                        Crear usuario
                      </Button>
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
            </Row>
          </Grid>
          <TableUsers
            title="Usuarios"
            subTitle="Lista de usuarios dados de alta"
            setLoading={value => this.setLoading(value)}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Users;
