import React from 'react';
// View components
import { Grid, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
// Button
import Button from 'components/CustomButton/CustomButton.jsx';
// Modal
import Modal from 'react-modal';
import InfoModal from 'components/InfoModal/InfoModal.jsx';
// React-Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';
// Firebase
import fire from 'config/fire.jsx';

class TableInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredVisits: [],
      loading: true,
      modalIsOpen: false
    };
  }
  componentDidMount() {
    let actionsData = [];
    let ref = fire
      .firestore()
      .collection('visits')

      if (this.props.type) {
        ref = ref.where('role', '==', this.props.type)
      }
      if (this.props.email) {
        ref = ref.where('userMail', '==', this.props.email)
      }
      
      ref.orderBy('createdAt', 'desc')
      .get()
      .then(filteredVisits => {
        for (let doc of filteredVisits.docs) {
          let auxDate;
          if (doc.data().createdAt !== '') {
            auxDate = doc.data().createdAt.toDate();
            auxDate = new Intl.DateTimeFormat('es-ES', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }).format(auxDate);
          } else {
            auxDate = '';
          }
          actionsData.push({
            ...doc.data(),
            createdAt: auxDate
          });
        }
        this.setState(
          {
            actionsData: actionsData,
            loading: false
          },
          () => {
            this.props.setLoading();
            this.props.value(this.state.actionsData);
          }
        );
      });
  }

  showModal(type, title, data1, data2) {
    this.setState({
      modalIsOpen: true,
      modalData: {
        type: type,
        title: title,
        data1: data1,
        data2: data2
      }
    });
  }

  closeModal = e => {
    e.stopPropagation();
    this.setState({ modalIsOpen: false });
  };

  render() {
    const placeHolderState = ({ filter, onChange }) => (
      <select
        style={{ width: '100%' }}
        name=""
        id=""
        value={filter ? filter.value : 'all'}
        onChange={e => onChange(e.target.value)}
      >
        <option value="all">Todos</option>
        <option value={true}>Entregado</option>
        <option value={false}>No entregado</option>
      </select>
    );
    const columns = [
      {
        Header: 'Comercial',
        accessor: 'userMail',
        minWidth: 300
      },
      {
        Header: 'Cliente',
        width: 300,
        accessor: 'client'
      },
      {
        Header: 'Dirección',
        width: 200,
        accessor: 'direction'
      },
      {
        Header: 'Dirección 2',
        width: 200,
        accessor: 'direction2'
      },
      {
        Header: 'Población',
        width: 150,
        accessor: 'town'
      },
      {
        Header: 'Provincia',
        width: 150,
        accessor: 'city'
      },
      {
        Header: 'Código Postal',
        width: 100,
        accessor: 'cp'
      },
      {
        Header: 'Teléfono',
        width: 150,
        accessor: 'phone'
      },
      {
        Header: 'Mail',
        width: 200,
        accessor: 'mail'
      },
      {
        Header: 'Mail 2',
        width: 200,
        accessor: 'mail2'
      },
      {
        Header: 'Contacto',
        width: 200,
        accessor: 'contact'
      },
      {
        Header: 'Fecha',
        width: 100,
        accessor: 'createdAt',
        Cell: ({ row }) => (
          <div
            style={{
              fontFamily: 'Monospace',
              textAlign: 'center'
            }}
          >
            {row.createdAt}
          </div>
        )
      },
      {
        Header: 'Catálogo',
        width: 100,
        accessor: 'catalogue',
        Filter: placeHolderState,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <input type="checkbox" disabled checked={row._original.catalogue} />
          </div>
        )
      },
      {
        Header: 'Granito',
        width: 100,
        accessor: 'granite',
        Filter: placeHolderState,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <input type="checkbox" disabled checked={row._original.granite} />
          </div>
        )
      },
      {
        Header: 'Enc. exp.',
        width: 100,
        accessor: 'worktop',
        Filter: placeHolderState,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <input type="checkbox" disabled checked={row._original.worktop} />
          </div>
        )
      },
      {
        Header: 'Enc. exp. Cover.',
        width: 100,
        accessor: 'worktopCover',
        Filter: placeHolderState,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <input
              type="checkbox"
              disabled
              checked={row._original.worktopCover}
            />
          </div>
        )
      },
      {
        Header: 'Exp. Cover.',
        width: 100,
        accessor: 'expoCover',
        Filter: placeHolderState,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <input type="checkbox" disabled checked={row._original.expoCover} />
          </div>
        )
      },
      {
        Header: 'Acceso panel',
        width: 100,
        accessor: 'panelAccess',
        Filter: placeHolderState,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <input type="checkbox" disabled checked={row._original.panelAccess} />
          </div>
        )
      },
      {
        Header: 'Muestras',
        width: 100,
        accessor: 'piece',
        Filter: placeHolderState,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <input type="checkbox" disabled checked={row._original.piece} />
          </div>
        )
      },
      {
        Header: 'Expositores entregados',
        id: 'books',
        width: 180,
        accessor: expositor => {
          let output = [];
          _.map(expositor.expositor, expo => {
            output.push(expo);
          });
          return output.join(', ');
        }
      },
      {
        Header: 'Posición',
        accessor: 'uid',
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <Button
              simple
              onClick={() =>
                this.showModal(
                  'map',
                  'Posición de la visita',
                  row._original.lat,
                  row._original.long
                )
              }
              style={{ padding: '0px' }}
            >
              <i className="fa fa-map-marker" />
            </Button>
          </div>
        )
      },
      {
        Header: 'Notas',
        accessor: 'uid',
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <Button
              simple
              onClick={() =>
                this.showModal('text', 'Notas', row._original.notes)
              }
              color="danger"
              style={{ padding: '0px' }}
            >
              <i className="fa fa-sticky-note-o" />
            </Button>
          </div>
        )
      }
    ];
    if (this.props.type === 'kitchen') {
      columns.splice(9, 0, {
        Header: 'Marmolista',
        accessor: 'marbleKitchen',
        width: 150
      });
    }

    return (
      <div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={{
            overlay: {
              backgroundColor: 'rgba(0,0,0,0.5)'
            },
            content: {
              padding: '0px',
              width: '45%',
              display: 'inline-block',
              left: '50%',
              top: '10%',
              bottom: '10%',
              transform: 'translateX(-50%)'
            }
          }}
        >
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Cerrar"
            onClick={this.closeModal}
            style={{ width: '25px', height: '25px' }}
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <InfoModal data={this.state.modalData} />
        </Modal>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <ReactTable
                ref={(r) => {
                  this.selectTable = r;
                }}
                onFilteredChange={() => {
                  const currentRecords = this.selectTable.getResolvedState().sortedData;
                  this.props.value(currentRecords)
                }}
                style={{
                  borderRadius: '25px',
                  padding: '10px',
                  background: 'linear-gradient(#F4F5F8, #F1F3F6)',
                  whiteSpace: 'nowrap'
                }}
                className="-striped -highlight"
                filterable
                loading={this.state.loading}
                defaultPageSize={10}
                previousText="Anterior"
                nextText="Siguiente"
                loadingText=""
                pageText="Página"
                rowsText="filas"
                ofText="de"
                noDataText="No se han encontrado proyectos"
                defaultFilterMethod={(filter, row) => {
                  if (row[filter.id] !== undefined) {
                    if (
                      typeof row[filter.id] === 'string' &&
                      filter.value === 'all'
                    ) {
                      return true;
                    } else if (typeof row[filter.id] === 'string') {
                      return row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                    } else if (typeof row[filter.id] === 'boolean') {
                      if (filter.value === 'all') return true;
                      return filter.value === String(row[filter.id]);
                    }
                  } else {
                    if (
                      filter.id === 'catalogue' ||
                      filter.id === 'granite' ||
                      filter.id === 'worktop' ||
                      filter.id === 'worktopCover' ||
                      filter.id === 'expoCover' ||
                      filter.id === 'panelAccess' ||
                      filter.id === 'piece'
                    ) {
                      if (filter.value === 'all') return true;
                      return filter.value === 'false';
                    }
                    return false;
                  }
                }}
                data={this.state.actionsData}
                columns={columns}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default TableInfo;
