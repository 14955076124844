import React from 'react';
import Logo from 'assets/img/marmoles-logo-black.png';
import styles from './Login.module.css';
import firebase from 'config/fire.jsx';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock
} from 'react-bootstrap';
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: '',
      pass: '',
      userState: null,
      userErrorMsg: false,
      passErrorMsg: false,
      passState: null,
      isSubmit: false
    };
  }
  changeUser = event => {
    this.setState({ user: event.target.value, userErrorMsg: false });
  };
  changePass = event => {
    this.setState({ pass: event.target.value, passErrorMsg: false });
  };
  handleSubmit = async e => {
    e.preventDefault();
    await firebase
      .auth()
      .signInWithEmailAndPassword(this.state.user, this.state.pass)
      .then(firebaseUser => {
        this.props.history.push('/');
      })
      .catch(error => {
        if (error.code === 'auth/wrong-password') {
          this.setState({ passErrorMsg: true });
        } else {
          this.setState({ userErrorMsg: true });
        }
      });
  };
  getUserState = () => {
    if (this.state.isSubmit) {
      if (this.state.userErrorMsg) return 'error';
      let reg = new RegExp(
        '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
      );
      if (reg.test(this.state.user)) return 'success';
      else return 'error';
    } else {
      return null;
    }
  };
  getPassState = () => {
    if (this.state.isSubmit) {
      if (this.state.passErrorMsg) return 'error';
      const length = this.state.pass.length;
      if (length > 3) return 'success';
      else return 'error';
    } else {
      return null;
    }
  };
  render() {
    return (
      <div className={styles['flex-wrapper']}>
        <div className={styles['login-wrapper']}>
          <form className={styles['form-signin']} onSubmit={this.handleSubmit}>
            <div className={styles['logo-img']}>
              <img src={Logo} alt="logo"/>
            </div>
            <h2 className={styles['form-signin-heading']}>WIKISOL</h2>
            <h4 className={styles['form-signin-heading']}>COMERCIALES</h4>
            <br />

            <FormGroup controlId="user" validationState={this.getUserState()}>
              <ControlLabel>Usuario</ControlLabel>
              <FormControl
                className={styles['login-input']}
                type="text"
                required
                onChange={this.changeUser}
                value={this.state.user}
              />
              {this.state.userErrorMsg ? (
                <HelpBlock>Usuario incorrecto</HelpBlock>
              ) : (
                ''
              )}
            </FormGroup>

            <FormGroup controlId="pass" validationState={this.getPassState()}>
              <ControlLabel>Contraseña</ControlLabel>
              <FormControl
                className={styles['login-input']}
                type="password"
                required
                onChange={this.changePass}
                value={this.state.pass}
              />
              {this.state.passErrorMsg ? (
                <HelpBlock>Contraseña incorrecta</HelpBlock>
              ) : (
                ''
              )}
            </FormGroup>

            <button
              className={styles['btn-login']}
              onClick={() => this.setState({ isSubmit: true })}
              type="submit"
            >
              INICIAR SESIÓN
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
