import LoginPage from 'views/Login/Login.jsx';

const pagesRoutes = [
  {
    path: '/public/login',
    name: 'Login',
    short: 'Login',
    mini: 'LP',
    component: LoginPage
  }
];

export default pagesRoutes;
