import React, { Component } from 'react';
import { NavItem, Nav } from 'react-bootstrap';
import firebase from 'config/fire.jsx';

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: ''
    };
  }
  componentDidMount() {
    var user = firebase.auth().currentUser;
    if (user) {
      this.setState({ userEmail: user.email });
    }
  }
  logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
      })
      .catch(function(error) {
        // An error happened.
      });
  };
  render() {
    return (
      <div>
        <Nav pullRight>
          <NavItem eventKey={1} disabled>
            <i style={{ marginRight: '5px' }} className="fa fa-user" />
            {this.state.userEmail}
          </NavItem>
          <NavItem eventKey={2} onClick={() => this.logOut()}>
            Salir
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default HeaderLinks;
